import React from "react";
import PropTypes from "prop-types";
import "@washingtonpost/tachyons-css/dist/index.css";
import "../resources/dist/washpost/main.css";
import { Favicon } from "@washingtonpost/site-favicons";
import { globalStyles } from "@washingtonpost/wpds-ui-kit";
import Head from "next/head";

// This default export is required in a new `pages/_app.js` file.
export default function MyApp({ Component, pageProps }) {
  globalStyles();
  return (
    <React.Fragment>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Favicon {...pageProps} />
      </Head>
      <Component {...pageProps} />
    </React.Fragment>
  );
}
MyApp.propTypes = {
  Component: PropTypes.any,
  pageProps: PropTypes.any
};
